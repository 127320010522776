<template>
  <div class="ui-select-checklist">
    <ui-item
      v-for="(option,index) in options"
      :key="index"
      icon-color="var(--ui-color-primary)"
      :icon="isSelected(option) ? 'mdi:checkbox-marked' : 'mdi:checkbox-blank-outline'"
      :text="option.text"
      class="ui-clickable ui-noselect"
      @click="setSelected(option)"
    ></ui-item>
  </div>
</template>
<script>
import { UiItem } from "@/modules/ui/components";

export default {
  name: "ui-select-checklist",
  components: {
    UiItem,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    isSelected(option) {
      if (!Array.isArray(this.value)) {
        return false;
      }

      return this.value.includes(option.value);
    },

    setSelected(option) {
      let value = Array.isArray(this.value) ? this.value.concat([]) : [];
      if (value.includes(option.value)) {
        value = value.filter((element) => element != option.value);
      } else {
        value.push(option.value);
      }
      this.$emit("input", value);
    },
  },
};
</script>