<template>
	<div class="ui-data-table">
		<ui-data-table-toolbar
			class="table-toolbar"
			:columns="columns"
			:rows="rows"
			:limit="limit"
			:page="page"
			@update:page="$emit('update:page', $event)"
			:visibleColumnNames.sync="visibleColumnNames"
			v-bind="$attrs"
		>
			<template #left>
				<slot name="toolbar-left"></slot>
			</template>

			<template #right>
				<slot name="toolbar-right"></slot>
			</template>
		</ui-data-table-toolbar>

		<div class="table-container">
			<table cellspacing="0" cellpadding="0">
				<thead>
					<tr>
						<th
							v-for="column in visibleColumns"
							:key="column.name"
							:title="column.label"
							:class="{ '--sticky': column.sticky }"
							:style="{ textAlign: column.align || 'left' }"
						>
							<div
								v-if="$slots['header-column'] || $scopedSlots['header-column']"
							>
								<slot name="header-column" :column="column"></slot>
							</div>
							<span v-else>{{ column.label }}</span>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr
						v-for="(row, i) in sanitizedRows"
						:key="i"
						:class="rowClassnames[i]"
						@click="$emit('click-row', rows[i])"
					>
						<td
							v-for="column in visibleColumns"
							:key="column.name"
							:class="{ '--sticky': column.sticky }"
							:style="{ textAlign: column.align || 'left' }"
						>
							<ui-output
								class="data-table-cell"
								:type="column.type"
								:value="row[column.name]"
								v-bind="column.props"
								:options="column.options"
							></ui-output>
						</td>
					</tr>
				</tbody>

				<tfoot>
					<tr v-for="(row, i) in sanitizedFooter" :key="i">
						<td
							v-for="column in visibleColumns"
							:key="column.name"
							:style="{ textAlign: column.align || 'left' }"
						>
							<ui-output
								v-if="column.options"
								:type="column.type"
								v-bind="column.props"
								class="data-table-cell"
								:value="row[column.name]"
							></ui-output>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>

		<footer class="table-footer" v-if="$slots.footer">
			<slot name="footer"></slot>
		</footer>
	</div>
</template>

<script>
import { getProperty } from '@/modules/cms/functions/getset.js';
import { UiOutput } from '@/modules/ui/components';
import UiDataTableToolbar from './TableToolbar.vue';

export default {
	name: 'ui-data-table',
	components: { UiDataTableToolbar, UiOutput },

	props: {
		columns: {
			type: Array,
			required: false,
			default: () => [],
		},

		rows: {
			type: Array,
			required: false,
			default: () => [],
		},

		footer: {
			type: Array,
			required: false,
			default: () => [],
		},

		limit: {
			type: [String, Number],
			required: false,
			default: 30,
		},

		page: {
			type: [String, Number],
			required: false,
			default: 1,
		},
	},

	data() {
		return {
			visibleColumnNames: [],
			rowClassnames: {},
			innerColumns: [],
		};
	},

	computed: {
		visibleColumns() {
			if (!this.visibleColumnNames.length) {
				return this.columns;
			}

			return this.columns.filter((col) =>
				this.visibleColumnNames.includes(col.name)
			);
		},

		sanitizedRows() {
			return this.rows.map((row, index) => {
				let data = {};
				this.columns.forEach(
					(column) => (data[column.name] = getProperty(row, column.name))
				);

				return data;
			});
		},

		sanitizedFooter() {
			return this.footer.map((row, index) => {
				let data = {};
				this.columns.forEach(
					(column) => (data[column.name] = getProperty(row, column.name))
				);

				return data;
			});
		},
	},

	methods: {
		highlightIndex(rowIndex) {
			this.$set(this.rowClassnames, rowIndex, '--highlighted');
			setTimeout(() => this.$set(this.rowClassnames, rowIndex, null), 2000);
			return true;
		},
	},
};
</script>

<style lang="scss">
@keyframes highlight {
	0% {
		background: #ff8;
	}
	100% {
		background: none;
	}
}

.ui-data-table {
	.table-toolbar {
		margin-bottom: var(--ui-breathe);
	}

	.table-container {
		overflow: auto;
		position: relative;
	}

	table {
		width: 100%;
		max-width: 100%;

		td,
		th {
			max-width: 300px;

			.data-table-cell {
				padding: 0 var(--ui-breathe);
				overflow: auto;
				max-height: 120px;
			}
		}

		tbody {
			// Grid / Borders
			td {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				border-right: 1px solid rgba(0, 0, 0, 0.1);
			}

			tr:hover {
				td {
					// color de hover de las filas.  No puede ser semitransparente porque
					// mostraria el contenido detras de columnas "sticky"
					background-color: rgb(255, 255, 211);
				}
			}

			tr.--highlighted {
				td {
					animation: highlight 2s;
				}
			}
		}

		thead {
			font-weight: bold;
			font-family: var(--ui-font-secondary);

			th {
				background-color: #fff;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding: 4px var(--ui-breathe);
				text-align: left;

				position: sticky;
				top: 0;
				z-index: 2;

				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.--sticky {
			position: sticky;
			left: 0;
			z-index: 3;
			background-color: #fff;
		}

		th.--sticky {
			z-index: 4;
		}

		tfoot {
			font-weight: bold;
		}
	}
}
</style>
